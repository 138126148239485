.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.cards {
    width: 400px;
    height: 450px;
    background-image: url('../../img/watermark.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    color: white;
    text-align: center;
    margin: 15px;
}

.cards-content {
    padding: 20px;
}

.student-data {
    margin-top: 80px;
    text-align: left;
    margin-left: 30px;
}
.text-data {
    width: 270px;
    text-align: left;
    margin-left: 30px;
}
@media print {

    .cards-container {
        display: flex;
        flex-wrap: wrap; /* Allow flex items to wrap to the next line */
        justify-content: space-around;
    }

    .cards {
        flex: 0 0 calc(50% - 30px); /* Set each card to occupy 50% of the container width minus margin */
        height: 450px;
        background-image: url('../../img/watermark.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        margin: 15px;
    }


    .break-page {
        page-break-after: always;
    }

    .student-data {
        margin-top: -60px;
        text-align: left;
        margin-left: -20px;
    }
    .text-data {
        width: 270px;
        text-align: left;
        margin-left: -20px;
    }
}